const initialState = {
  darkTheme: true
};

const TOGGLE_THEME = 'TOGGLE_THEME';

export const toggleTheme = () => ({ type: TOGGLE_THEME });

export default (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_THEME:
      return { ...state, darkTheme: !state.darkTheme };
    default:
      return state;
  }
};
